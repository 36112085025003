<style lang="stylus" scoped>

</style>

<template lang="pug">
  div#postPage

    v-container#post-list(fluid)
      v-row
        v-col(v-show="!loading" xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3')
          v-btn(icon @click="$router.go(-1)")
            v-icon mdi-arrow-left
          Post(
            :key="post.id"
            :post='post'
            @confirmAction="callConfirmAction"
            @edit="editPost"
            :refreshWatch='refreshWatch'
          )
        v-col.text-center( cols='12' xs='12' sm='8' lg='6' offset-sm='2' offset-lg='3' v-show="loading" size=100 key='flex')
          v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
    v-dialog(v-model="flags.postForm" :fullscreen="$vuetify.breakpoint.smAndDown")
      //-:key="postForm.keyCode"
      PostForm(:post="post" @close="closePostForm" @created="postCreated" @edited="postEdited")
    v-dialog(v-model='dialogConfirmation.model' persistent max-width='290')
      v-card
        v-card-title.headline
          | {{dialogConfirmation.title}}
        v-card-text
          | {{dialogConfirmation.body}}
        v-card-actions
          .flex-grow-1
          v-btn(color='grey darken-1' text='' @click='dialogConfirmation.model = false; dialogConfirmation.confirmed = false') Cancelar
          v-btn(color='green darken-1' text='' @click='dialogConfirmation.model = false; dialogConfirmation.confirmed = true') Sim
</template>

<script>
import PostsApi from '../api/posts'
import Post from '../components/feed/PostViewer'
import PostForm from '../components/post/PostForm'

const getPost = async (self) => {
  console.log('POST ID', self.$route.params.postId)
  self.loading = true
  const postResult = await PostsApi.list({
    postId: self.$route.params.postId
  })
  console.log('POST_RESULT', postResult)
  self.refreshWatch++
  self.post = postResult[0]
  self.loading = false
}
export default {
  name: 'PostPage',
  components: {
    Post,
    PostForm
  },
  data () {
    return {
      loading: true,
      postEditorVisible: false,
      dialogConfirmation: {
        model: false,
        confirmed: false,
        title: 'Confirmação',
        body: 'Tem deseja que deseja confirmar essa ação?',
        funcCallback: null
      },
      refreshWatch: 0,
      snackbar: {
        text: '',
        enable: false
      },
      flags: {
        postForm: false
      },
      post: {}
    }
  },
  methods: {
    editPost (post) {
      this.flags.postForm = true
    },
    async callConfirmAction (options) {
      this.dialogConfirmation.model = true
      this.dialogConfirmation.title = options.title
      this.dialogConfirmation.body = options.body
      this.snackbar.text = options.snackbarText
      this.dialogConfirmation.funcCallback = options.func
    },
    showAction (text) {
      this.snackbar.text = text || this.snackbar.text
      this.snackbar.enable = true
    },
    closePostForm () {
      this.flags.postForm = false
    },
    postCreated () {
      this.closePostForm()
      this.showAction('Postagem criada com sucesso!')
    },
    postEdited () {
      this.closePostForm()
      this.showAction('Postagem editada com sucesso!')
    }
  },
  async activated () {
    console.log('Activated')
    await getPost(this)
  },
  async mounted () {
    console.log('Mounted')
    await getPost(this)
  }
}
</script>
