<template lang="pug">
    v-card(
      :loading="loadings.propagateAssocsUpdate"
      flat
    )
      v-list
          v-subheader
              | Atualizações
          v-list-item(@click="propagateAssocsUpdate")
              v-list-item-icon
                  v-icon mdi-update
              v-list-item-content
                  v-list-item-title Propagar atualizações de associações
</template>

<script>
import updatesApi from '../api/updates'
import { createModuleLogger } from '../configs/winston'

const l = createModuleLogger('Updates')

export default {
  data () {
    return {
      loadings: {
        propagateAssocsUpdate: false
      }
    }
  },
  methods: {
    async propagateAssocsUpdate () {
      try {
        this.loadings.propagateAssocsUpdate = true
        await updatesApi.propagateAssocsUpdate()
        this.loadings.propagateAssocsUpdate = false
      } catch (err) {
        l.error(err.message)
        this.loadings.propagateAssocsUpdate = false
      }
    }
  }
}
</script>
