<template lang="pug">
  v-container(fluid)
    v-snackbar(v-model="snackbar.enabled" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="snackbar.enabled = false" ) Fechar
    v-dialog(
      v-model="flags.photoEditor"
    )
      PhotoEditor(
        ref="photoEditor"
        @close="closePhotoEditor"
        @save="saveNewProfileImage"
        :image="newProfileImage"
        :imageName="newProfileImageFileName"
      )
    v-card(tile flat)
      v-row.justify-center.align-center.text-center(v-if="validatePermissions(['me.profileImage.update'])" dense)
        v-progress-circular.mt-5(
          v-if="loadings.profileImage || loadings.me"
          :size="50"
          color="primary"
          indeterminate
        )
        v-badge(
          v-else
          icon="mdi-pencil"
          bottom
          right
          overlap
          bordered
          offset-x="25"
          offset-y="25"
        )
          v-avatar(size="100" @click="openPhotoEditor")
            v-img(v-if="me.profile_image_url" :src="me.profile_image_url")
            v-icon(
              v-else
              size="100"
              color="primary"
            ) mdi-account-circle
      v-row.justify-center.align-center.text-center(v-else)
        v-avatar(size="100")
          v-img(v-if="me.profile_image_url" :src="me.profile_image_url")
          v-icon(
            v-else
            size="100"
            color="primary"
          ) mdi-account-circle
      v-row.justify-center.align-center.text-center
        v-card-title.justify-center.headline {{ basicInfos.Nome }}
    v-container
      v-list(two-line subheader)
        v-row
          v-col(:cols="$vuetify.breakpoint.mdAndUp ? 6 : 12")
            v-subheader Informações Basicas
            v-row(v-if="!loadings.me")
              v-list-item(v-for="(value, key) in basicInfos" :key="key")
                v-col
                  v-list-item-title {{ key }}
                  v-list-item-subtitle {{ value }}
            v-row(v-else justify="center" align="center")
              v-progress-circular(indeterminate color="primary")
          v-col(:cols="$vuetify.breakpoint.mdAndUp ? 6 : 12")
            v-subheader Contatos
            v-row(v-if="!loadings.me")
              v-list-item(v-for="(value, key) in contactInfos" :key="key")
                v-col
                  v-list-item-title {{ key }}
                  v-list-item-subtitle {{ value }}
            v-row(v-else justify="center" align="center")
              v-progress-circular(indeterminate color="primary")
          v-col(:cols="$vuetify.breakpoint.mdAndUp ? 6 : 12")
            v-subheader Grupos
            v-row(v-if="!loadings.me")
              v-list-item(v-for="(value, i) in me.groups" :key="`group-${i}`")
                v-col
                  v-list-item-title {{ value.name }}
                  v-list-item-subtitle {{ getUserRoleName(value.role) }}
            v-row(v-else justify="center" align="center")
              v-progress-circular(indeterminate color="primary")
          v-col(v-if="me.type === 'parent'" :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12")
            v-subheader Estudantes
            v-row(v-if="!loadings.students && !loadings.me")
              v-list-item(v-if="students.length > 0" v-for="(value, i) in students" :key="`student-${i}`")
                v-col
                  v-list-item-title {{ value.name }}
              span(v-else).caption Nenhum estudante associado
            v-row(v-else justify="center" align="center")
              v-progress-circular(indeterminate color="primary")
</template>

<script>
import Post from '../components/feed/PostViewer'
import responsibleApi from '../api/responsible'
import PhotoEditor from '../components/users/PhotoEditor'
import randomBytes from 'randombytes'
import Permissions from '../utils/permissionsManager'

export default {
  components: {
    Post,
    PhotoEditor
  },
  data () {
    return {
      loadings: {
        students: false,
        me: false,
        profileImage: false
      },
      flags: {
        photoEditor: false
      },
      snackbar: {
        enabled: false,
        text: null
      },
      tabItems: ['Info', 'Posts'],
      tab: null,
      students: [],
      newProfileImage: null,
      newProfileImageFileName: null,
      validatePermissions: Permissions.validatePermissions
    }
  },
  computed: {
    basicInfos () {
      const data = {
        'Nome': this.me.name,
        'Sexo': this.me.gender || 'Não especificado',
        'Tipo': this.me.type_name
      }

      if (this.me.type === 'aluno') {
        data['Matrícula'] = this.me.matricula || 'Não especificada'
      } else {
        data['CPF'] = this.me.cpf || 'Não especificado'
      }

      return data
    },
    contactInfos () {
      const data = {
        'Email': this.me.email || 'Não especificado',
        'Telefones': this.me.telefones || 'Não especificado'
      }

      return data
    },
    me () {
      return this.$store.getters['user/me']
    }
  },
  async created () {
    this.loadings.me = true
    await this.$store.dispatch('user/getMe')
    this.loadings.me = false

    if (this.me.type === 'parent') {
      this.loadings.students = true
      this.students = await responsibleApi.getChildren(this.me.cpf)
      this.loadings.students = false
    }
  },
  methods: {
    async saveNewProfileImage (url) {
      this.loadings.profileImage = true

      try {
        await this.$store.dispatch('user/updateProfileImage', {
          imageUrl: url
        })
      } catch (err) {
        this.snackbar.text = err.message
        this.snackbar.enabled = true
      }

      this.loadings.profileImage = false
    },
    openPhotoEditor () {
      this.flags.photoEditor = true

      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'

      fileInput.onchange = e => {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = () => {
          this.newProfileImage = reader.result
          this.newProfileImageFileName = randomBytes(30).toString('hex') + '.' + file.name.split('.').slice(1).join('.')
        }

        if (file) {
          reader.readAsDataURL(file)
        } else {
          this.closePhotoEditor()
        }
      }

      fileInput.click()
    },
    closePhotoEditor () {
      this.flags.photoEditor = false
      this.newProfileImage = null
      this.newProfileImageFileName = null
    },
    getUserRoleName (slug) {
      switch (slug) {
        case 'publisher':
          return 'Editor'
        case 'viewer':
          return 'Visualizador'
        case 'moderator':
          return 'Moderador'
        default:
          return 'Sem papel no grupo'
      }
    }
  }
}
</script>
