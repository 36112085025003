<template lang="pug">
  v-container
    v-stepper(
      v-model='step'
      touchless="false"
    )
      v-stepper-header
        v-stepper-step(:complete="step > 1" step="1" editable) Selecionar Relatório
        v-divider
        v-stepper-step(:complete="step > 2" step="2" editable) Data
        v-divider
        v-stepper-step(:complete="step > 3" step="3" editable) Destinatários
        v-divider
        v-stepper-step(:complete="step > 4" step="4" editable) Enviar
      v-stepper-items
        v-stepper-content(step="1")
          v-row(align='center' justify='center' v-if='loadings.reports')
            v-col
              span.caption.grey--text Listando agendas diárias
            v-col.text-center(cols='12')
              v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
          v-row(justify-center v-else)
            v-col(cols='12')
              span.caption.grey--text Selecione um relatório diário para preencher
            v-col(cols='12')
              v-list
                div( v-for="(report, index) in reports.reports" :key="report.id")
                  v-divider(v-if='index > 0')
                  v-list-item(@click="selectReport(report)" large)
                    v-list-item-avatar
                      v-icon assignment
                    v-list-item-content {{report.title}}
        v-stepper-content(step="2")
          v-row.pb-5
            v-col(cols='auto')
              v-date-picker.mt-4(v-model="form.date" full-width :landscape="$vuetify.breakpoint.smAndUp")
          v-row(justify="space-between")
            v-col(cols='auto')
              v-btn(@click="prevStep()" text) Voltar
            v-col(cols='auto')
              v-btn.primary(@click="nextStep()") Continuar
        v-stepper-content(step="3")
          v-window(v-model='states.recipients')
            v-window-item(value='group')
              v-row(align='center' justify='center' v-if='loadings.groups')
                v-col
                  span.caption.grey--text Listando turmas
                v-col.text-center(cols='12')
                  v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
              v-row(justify-center v-else)
                v-col(cols='12')
                  span.caption.grey--text Selecione uma turma para continuar
                v-col(cols='12')
                  v-list(v-if="report.groups && report.groups.length > 0")
                    template(v-for="group in report.groups")
                      v-list-item(@click="selectGroup(group)" large :key="group.cod")
                        v-list-item-avatar
                          v-icon assignment
                        v-list-item-content {{group.name}}
                      v-divider
                  v-row(v-else)
                    v-col(cols='auto')
                      div.text-center
                        v-icon mdi-alert
                      div.mt-2.caption Infelizmente nenhuma turma que você que tem acesso está associada a esse item, por favor entre em contato com o suporte caso isto pareça um equivoco.
              v-row
                v-btn(@click="prevStep()" text) Voltar
            v-window-item(value='people')
              v-row( align='center' justify='center' v-if='loadings.people')
                v-col
                  span.caption.grey--text Listando alunos
                v-col.text-center(cols='12')
                  v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
              v-row(justify-center v-else)
                v-col(cols='12')
                  v-list
                    div(v-for="(person, index) in group.people" :key="person.id")
                      v-divider(v-if='index > 0')
                      v-list-item( @click="selectPerson(person)" large )
                        v-list-item-avatar.mr-3
                          v-icon(color="primary" v-if='person.published') done
                          v-icon(color="warning" v-if='!person.published') create
                          //-v-btn(fab small color='primary' :disabled='!person.published')
                        v-list-item-avatar.mr-2(v-if="person.image_url")
                          img(:src="person.image_url")
                        v-list-item-avatar(v-else)
                          v-icon mdi-account-circle
                        //-v-list-item-avatar.mr-3
                          v-icon person
                        v-list-item-content {{ person.name }}
              v-row
                v-btn(@click="prevStep()" text) Voltar
        v-stepper-content(step="4")
          v-container
            v-row(align='center' justify='center' v-if='loadings.component')
                  v-col
                    span.caption.grey--text Carregando componente
                  v-col.text-center(cols='12')
                    v-progress-circular.mt-5(:size="50" color="primary" indeterminate)
            v-row(v-else)
              component(
                :is="report.component"
                :reportType="report.type"
                :date="form.date"
                :group="group"
                :person="person"
                :dateTitle="dateTitle"
                :step="step"
                @sendReport="sendReport"
                @comeBack='sendNextReport'
              )
    v-snackbar( v-model="states.snackbar" :timeout="6000" top right ) {{ snackbar.text }}
      v-btn( color="pink" text @click="states.snackbar = false" ) Fechar
    v-dialog(v-model='states.error' width='300')
      v-card
        v-card-title
          | Ocorreu um erro ao enviar a postagem
        v-card-text
          | {{error.text}}
    v-overlay(:value="states.overlay")
      v-progress-circular(indeterminate size="64")
</template>

<script>
import { mapState } from 'vuex'
import importExternalComponent from '../utils/importExternalComponent'
import PostsApi from '../api/posts'

export default {
  data: function () {
    return {
      step: 1,
      states: {
        recipients: 'group',
        error: false,
        snackbar: false,
        overlay: false
      },
      snackbar: {
        text: ''
      },
      error: {
        text: ''
      },
      report: {
        groups: [],
        component: null,
        type: 'individual'
      },
      form: {
        date: this.$moment().format('YYYY-MM-DD')
      },
      group: {
        people: []
      },
      person: {},
      parents: [],
      loadings: {
        reports: false,
        groups: false,
        people: false,
        component: false
      },
      dateTitle: ''
    }
  },
  watch: {
    step (value) {
      if (value < 3) {
        this.states.recipients = 'group'
        this.group = {
          people: []
        }
        this.person = {}
      }
    }
  },
  methods: {
    async listUsersStatus (people) {
      const peopleList = people || this.group.people
      const result = await PostsApi.getUsersAlreadyPostCategoryPubslished({
        users: peopleList.map(p => p.id),
        publication_time: this.form.date,
        category: 4
      })

      if (result && result.data && result.data.length > 0) {
        result.data[0].map((pub) => {
          const person = peopleList.filter(p => p.id.toString() === pub.user.toString())[0]
          if (person) {
            person.published = pub.quantity > 0
            person.publishedQuantity = pub.quantity
          }
        })
      }
    },
    showAction (text) {
      this.snackbar.text = text || this.snackbar.text
      this.states.snackbar = true
    },
    nextStep () {
      this.step++
    },
    prevStep () {
      this.step--
    },
    async selectReport (report) {
      this.nextStep()

      // Get report groups
      this.loadings.groups = true

      const groups = await this.$store.dispatch('dynamicComponents/listGroups', report.path)
      this.report.groups = groups

      this.loadings.groups = false

      // Get external report component
      this.loadings.component = true
      this.report.component = await importExternalComponent(report.path)
      this.loadings.component = false
    },
    async selectGroup (group) {
      this.group = group

      if (this.group.to_user) {
        // Get selected group users
        this.states.recipients = 'people'

        this.loadings.people = true

        const people = await this.$store.dispatch('groups/peopleList', { group_cod: group.cod, type: 'aluno' })
        this.group.people = people
        await this.listUsersStatus(people)

        this.loadings.people = false

        this.report.type = 'individual'
      } else if (this.group.to_group) {
        this.report.type = 'group'
        this.nextStep()
      }
    },
    async selectPerson (aluno) {
      this.nextStep()
      this.states.recipients = 'people'

      this.loadings.component = true

      this.person = aluno
      // const parents = await this.$store.dispatch('responsible/get', aluno.matricula)
      // this.parents = parents

      this.loadings.component = false
    },
    async sendNextReport () {
      if (this.report.type === 'individual') {
        this.states.recipients = 'people'
        this.loadings.people = true
        await this.listUsersStatus(this.group.people)
        this.loadings.people = false
      } else {
        this.states.recipients = 'group'
      }
      this.step = 3
    },
    async sendReport (data) {
      this.states.overlay = true
      const time = this.$moment().format('HH:mm:ss')
      console.log('Computer time: ', time)
      const post = {
        title: data.title,
        publication_time: `${this.form.date} ${time}`,
        body: data.body,
        category_id: 4,
        medias: [],
        to: []
      }

      if (this.report.type === 'individual') {
        console.log('User', this.person)
        console.log('UserId', this.person.id)
        post.to.push({
          type: 'user',
          bonds: ['self', 'aluno', 'parent'],
          user_id: this.person.id
        })
        // this.parents.forEach(parent => {
        //   post.to.push({
        //     type: 'user',
        //     user_id: parent.id,
        //     bonds: ['self', 'aluno', 'parent']
        //   })
        // })
      } else {
        post.to.push({
          type: 'group',
          group_cod: this.group.cod,
          bonds: ['aluno', 'parent']
        })
      }

      try {
        await this.$store.dispatch('posts/create', post)

        this.showAction('Agenda diária publicada com sucesso!')
        this.sendNextReport()
      } catch (err) {
        console.error(err)
        this.states.error = true
        this.error.text = err.message
        this.showAction('Erro ao publicar agenda diária')
      }

      this.states.overlay = false
    }
  },
  computed: {
    ...mapState(['reports'])
  },
  async created () {
    this.dateTitle = this.$moment(this.date).format('ll')

    this.loadings.reports = true
    await this.$store.dispatch('reports/list')
    this.loadings.reports = false
  }
}
</script>
