<template lang="pug">
  v-app#login
    v-container.primary(fluid fill-height)
      v-snackbar(v-model="flags.snackbar") {{snackbarText}}
        v-btn(color="pink" text @click.native="flags.snackbar = false") Fechar
      v-row(justify='center' align='center')
        v-col(sm=8 md=7 lg=6)
          v-card()
            v-card-title.title.font-weight-regular
              span Digite uma Nova Senha
            v-window
                //- RECUPERAR SENHA
                v-card-text
                  v-container
                    v-row(justify='center' align='center')
                      v-col(md=10 lg=8)
                        v-form(ref='passwordForm' v-model='validPasswordForm' lazy-validation)
                          v-text-field.pt-3(v-model='password' :rules="[rules.passwordRequired]"  label='Digite a nova senha' prepend-inner-icon='mdi-lock-question' :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'visibility' : 'visibility_off'" @click:append="showPassword = !showPassword" outlined autofocus)
                          v-text-field.pt-3(@keyup.enter='sendNewPassword' v-model='confirmPassword' :rules="[rules.passwordMatch]" label='Confirme a senha' prepend-inner-icon='mdi-lock-question' :type="showConfirmPassword ? 'text' : 'password'" :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'" @click:append="showConfirmPassword = !showConfirmPassword" outlined autofocus)
                          div(v-if="hasResponse")
                            span.subtitle-1() {{ changeStatus }}
            v-card-actions
              v-scale-transition
                v-btn(text @click="goToLogin") Voltar ao login
              v-spacer
              v-scale-transition
                v-btn.primary(@click="sendNewPassword" :loading="flags.loadingPasswordRest") Modificar Senha
</template>

<script>
import Post from '../api/user'

export default {
  data () {
    return {
      flags: {
        loadingPasswordRest: false,
        snackbar: false
      },
      snackbarText: '',
      password: null,
      confirmPassword: null,
      showPassword: false,
      showConfirmPassword: false,
      validPasswordForm: true,
      hasResponse: false,
      changeStatus: 'teste',
      rules: {
        passwordRequired: value => !!value || 'Preencha a senha.',
        passwordMatch: (value) => {
          if (value === this.password) {
            return true
          } else {
            return 'As senhas precisam ser iguais'
          }
        }
      },
      profiles: null
    }
  },
  methods: {
    async sendNewPassword () {
      if (this.validPasswordForm) {
        var hash = this.$route.params.hash
        var post = {
          token: hash,
          password: this.password
        }
        this.flags.loadingPasswordRest = true
        var res = await Post.createNewPassword(post)
        this.flags.loadingPasswordRest = false
        console.log(res)
        if (res.status === 200) {
          this.changeStatus = res.data
          this.hasResponse = true
          this.snackbarText = 'Senha redefinida com sucesso'
          this.flags.snackbar = true

          setTimeout(() => {
            this.goToLogin()
          }, 3000)
        } else if (res.status === 404) {
          this.changeStatus = res.data.message
          this.hasResponse = true
          this.snackbarText = 'Solicite um novo link para redefinição de senha'
          this.flags.snackbar = true
        }
      }
    },
    goToLogin () {
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
<style scoped lang="stylus">
  // #login
  //   height: 50%
  //   width: 100%
  //   position: absolute
  //   top: 0
  //   left: 0
  //   content: ""
  //   z-index: 0
</style>
