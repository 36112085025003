<template lang="pug">
  v-app
    v-content.primary
      v-container(fill-height fluid)
        v-row(justify='center' align='center')
          v-col(sm=8 md=7 lg=6)
            v-card(outlined )
              v-row(justify='center' align='center')
                v-col(cols="auto" align-self="center")
                    v-img.ma-4.align-self-center(src='https://firebasestorage.googleapis.com/v0/b/beyond-quoti.appspot.com/o/quoti_logo_bg_blug_round_icon.png?alt=media&token=75a9c5de-b8dc-4ca8-b797-470f564872cb' alt='Imagem' max-height="200" max-width="200"  contain)
              v-row(justify='center' align='center')
                v-col(cols="auto")
                  v-autocomplete.mb-2(:loading="loading" autofocus outlined color="primary" v-model="school" label="Instituições" :items="items" :filter="filter" :search-input.sync="search" :no-data-text="showNoData" placeholder="Digite o nome da instituição" hide-details item-text="name" item-value="slug" @input="sendToLogin")
</template>

<script>
import institutions from '../api/institutions'
export default {
  data () {
    return {
      school: '',
      search: '',
      smallSearch: true,
      showSchools: false,
      loading: true,
      schools: []
    }
  },
  async created () {
    try {
      this.schools = (await institutions.list()).data
      this.loading = false
    } catch (err) {
      console.error(err)
      this.loading = false
    }
  },
  computed: {
    items () {
      if (!this.showSchools) {
        return ['Digite o nome da instituição']
      } else {
        return this.schools
      }
    },
    showNoData () {
      if (this.smallSearch) {
        return 'Digite o nome da instituição'
      } else {
        return 'Nenhuma instituição com esse nome'
      }
    }
  },
  watch: {
    search (val) {
      if (val === '' || val === null) {
        this.showSchools = false
      } else {
        this.showSchools = true
      }
    }
  },
  methods: {
    filter (item, queryText, itemText) {
      if (queryText.length < 3) {
        this.smallSearch = true
        return false
      } else {
        this.smallSearch = false
        return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
      }
    },
    sendToLogin () {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.autocompleteClass {
  opacity : 1;
}
</style>
