<template lang="pug">
  v-container(fluid)
    v-stepper(v-model="step")
      v-stepper-header
        v-stepper-step( :complete="step > 0" step="0") Turmas
        v-divider
        v-stepper-step( :complete="step > 1" step="1") Data
        v-divider
        v-stepper-step( :complete="step > 1" step="2") Alunos
      v-stepper-items
        v-stepper-content(step="0" v-if="!groupsChildren")
          v-row.justify-center
            v-progress-circular(size="70" indeterminate color="primary")
        v-stepper-content(step="0" v-else)
          v-container
            v-row(justify-center)
              v-col(cols='12')
                span.caption.grey--text Selecione uma turma para continuar
              v-col(cols='12')
                v-row.justify-center(v-if="loadingGroups")
                  v-progress-circular(size="70" indeterminate color="primary")
                v-list(dense v-else)
                  div(v-for="(group,index) in groupsChildren.turmas" :key="group.cod")
                    v-divider(v-if='index > 0')
                    v-list-item(@click="selectTurma(group)" large)
                      v-list-item-avatar
                        v-icon assignment
                      v-list-item-content {{group.name}}
        v-stepper-content(step="1")
          v-container
            v-row.pb-5
              v-date-picker.mt-4(v-model="date" full-width :landscape="$vuetify.breakpoint.smAndUp")
            v-row
              v-btn(@click="step--" text) Voltar
              v-spacer
              v-btn.primary(@click="step++") Continuar
        v-stepper-content(step="2")
          v-row.justify-center(v-if="loadingStudents")
            v-progress-circular(size="70" indeterminate color="primary")
          v-list(dense v-else)
            v-list-item-group(v-model="selectedStudents" multiple)
              template(v-for="(student,i) in students")
                v-list-item(:key="`student-${i}`" :value="student" color="success")
                  template(v-slot:default="{ active, toggle }")
                    v-list-item-action()
                      v-row.justify-start.align-center(dense)
                        v-col
                          v-avatar(v-if="student.image")
                            v-img(:src="student.image")
                          v-avatar(v-else)
                            v-icon(large) mdi-account-circle
                    v-list-item-content() {{ student.name }}
            v-row
              v-btn.ml-5.mt-5(@click="step--" text) Voltar
              v-spacer
              v-btn.mr-5.mt-5(color="primary" dark @click="savePresence" :loading="uploadingPresence") Enviar
    v-snackbar(v-model="showSnackbar") {{ snackBarText }}
      v-btn(color="pink" text @click="showSnackbar = false") Fechar
</template>
<script>
import groups from '../api/organizationgroups.js'
import { mapFields } from 'vuex-map-fields'
import presence from '../api/presence'
export default {
  data () {
    return {
      turmas: [],
      loadingTurmas: false,
      step: 0,
      selectedTurma: null,
      loadingGroups: true,
      loadingStudents: true,
      selectedStudents: [],
      uploadingPresence: false,
      students: [],
      showSnackbar: false,
      snackBarText: '',
      date: new Date().toISOString().substr(0, 10),
      isUpdating: false
    }
  },
  methods: {
    selectTurma (turma) {
      this.selectedTurma = turma
      this.getStudents()
      this.step = 1
    },
    async getStudents () {
      this.loadingStudents = true
      var res = await groups.listAssocs({ group_cod: this.selectedTurma.cod, type: 'aluno' })
      this.students = res
      try {
        res = await presence.list({
          data: this.date,
          groupId: this.selectedTurma.id
        })
      } catch (err) {
        console.error(err)
      }
      var index = this.students.length - 1
      this.isUpdating = false
      while (index >= 0) {
        this.isUpdating = true
        for (let student of res.data) {
          if (this.students[index].id === student.student_id && student.presence === 'true') {
            this.selectedStudents.push(this.students[index])
          }
        }
        index--
      }
      this.loadingStudents = false
    },
    async savePresence () {
      this.uploadingPresence = true
      var studentsObj = []
      for (let student of this.students) {
        if (this.selectedStudents.includes(student)) {
          studentsObj.push({
            presence: 'true',
            data: this.date,
            group_id: this.selectedTurma.id,
            disc_id: 213,
            student_id: student.id
          })
        } else {
          studentsObj.push({
            presence: 'false',
            data: this.date,
            group_id: this.selectedTurma.id,
            disc_id: 213,
            student_id: student.id
          })
        }
      }
      try {
        if (this.isUpdating) {
          await presence.update({
            data: this.date,
            groupId: this.selectedTurma.id,
            presence: studentsObj
          })
        } else {
          await presence.create(studentsObj)
        }
        this.uploadingPresence = false
        this.showSnackbar = true
        this.snackBarText = 'presença salva com sucesso'
        this.step = 0
      } catch (err) {
        console.error(err)
        this.uploadingPresence = false
        this.showSnackbar = true
        this.snackBarText = err.response.data.message
      }
    }
  },
  computed: {
    ...mapFields('groups', ['groupsChildren'])
  },
  async created () {
    await this.$store.dispatch('groups/list')
    this.loadingGroups = false
  }
}
</script>
<style>
</style>
