<template lang="pug">
  v-container
    v-btn.primary(fixed fab bottom right @click="create()")
      v-icon add
    v-row(justify-center)
      v-col(cols='12')
        v-data-table(
          :headers="permissionsTableHeaders"
          :items="permissions"
          item-key="name"
          class="elevation-1"
          :loading="loadings.data"
        )
          template(v-slot:item.edit="{ item }")
            v-icon(small class="mr-2" @click="editItem(item)") edit
          template(v-slot:item.delete="{ item }")
            v-icon(small @click="deleteItem(item)") delete
    v-dialog(
      v-model='flags.dialog'
      :persistent="loadings.form"
      scrollable=true
      max-width="700px"
    )
      v-form
        v-card(style='border-radius: 13px;')
          v-toolbar.px-4.toolbar-settings.text-settings.white--text(color='primary')
            v-card-title {{flags.create ? 'Nova Permissão' : 'Editar Permissão'}}
            v-spacer
            v-btn(icon @click='flags.dialog = false')
              v-icon(color='white') mdi-close
          VuePerfectScrollbar
            v-card-text.pa-0
              VuePerfectScrollbar
                v-form
                  v-card(flat tile)
                    v-card-text.pt-0
                      v-container
                        v-row
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Nome da permissão
                            v-text-field(v-model='form.name' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Ícones
                            v-autocomplete(v-model="form.icon" :items="iconList" hide-details dense required outlined)
                              template(v-slot:item="data")
                                  v-divider
                                  v-list-item
                                    v-list-item-avatar(size="40")
                                      v-icon {{data.item}}
                                    v-list-item-content {{data.item}}
                              template(v-slot:selection="data")
                                  v-divider
                                  v-list-item
                                    v-list-item-avatar(size="20")
                                      v-icon {{data.item}}
                                    v-list-item-content {{data.item}}
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Caminho
                            v-text-field(v-model='form.path' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Recurso
                            v-text-field(v-model='form.resource' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Tipo
                            v-text-field(v-model='form.type' :counter='100' hide-details dense required outlined)
          v-card-actions.pb-3
            v-spacer
            v-scale-transition
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-if="flags.create") Criar Permissão
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-else) Salvar Permissão
    v-dialog(v-model='deleteInfo.dialog' max-width='600px')
      v-card
      v-form
        v-card(outlined)
          v-card-title Atenção:
          v-card-text Tem certeza que deseja remover essa permissão?
          v-card-actions
            v-btn.text-none(text @click.stop='deleteInfo.dialog = false') Cancelar
            v-spacer
            v-btn.text-none(color='primary' :loading='deleteInfo.loading' dark @click.stop='callDelete()') Remover
</template>

<script>
import icons from '../assets/icons'
import colors from '../assets/colors'
import { mapState } from 'vuex'
import permissionsApi from '../api/permissions'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...mapState(['auth'])
  },
  data: function () {
    return {
      iconList: icons,
      colorsList: colors,
      permissions: [],
      permissionClicked: '',
      permissionsTableHeaders: [
        {
          text: 'Nome',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Tipo',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Editar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'Deletar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'delete'
        }
      ],
      flags: {
        dialog: false,
        create: false
      },
      loadings: {
        data: false,
        form: false
      },
      deleteInfo: {
        dialog: false,
        loading: false
      },
      form: {
        name: '',
        icon: '',
        path: '',
        resource: '',
        type: '',
        id: ''
      }
    }
  },
  watch: {
    'flags.dialog': function (value) {
      if (!value) {
        this.clear()
      }
    }
  },
  methods: {
    clear () {
      // Clear form
      this.form.name = ''
      this.form.icon = ''
      this.form.path = ''
      this.form.resource = ''
      this.form.type = ''
    },
    deleteItem (permission) {
      this.deleteInfo.dialog = true
      this.permissionClicked = permission
    },
    async callDelete () {
      this.deleteInfo.loading = true
      await permissionsApi.deletePermission(this.permissionClicked.id)
      this.permissions = await permissionsApi.getPermissions()
      this.permissionClicked = ''
      this.deleteInfo.loading = false
      this.deleteInfo.dialog = false
    },
    async editItem (permission) {
      console.log(permission)
      // Select permission
      this.form.name = permission.name
      this.form.icon = permission.icon
      this.form.path = permission.path
      this.form.resource = permission.resource
      this.form.type = permission.type
      this.form.id = permission.id
      // Set edit flags
      this.flags.create = false
      this.flags.dialog = true
    },
    create () {
      // Set create flags
      this.flags.create = true
      this.flags.dialog = true
    },
    async save () {
      this.loadings.form = true

      try {
        // Save menu item
        const savedData = {
          name: this.form.name,
          icon: this.form.icon,
          path: this.form.path,
          resource: this.form.resource,
          type: this.form.type
        }
        // Update data on table
        if (this.flags.create) {
          await permissionsApi.savePermission(savedData)
          this.permissions = await permissionsApi.getPermissions()
        } else {
          await permissionsApi.updatePermission(savedData, this.form.id)
          this.permissions = await permissionsApi.getPermissions()
        }
      } catch (err) {
        console.error(err)
      }

      this.loadings.form = false
      this.flags.dialog = false
    }
  },
  async created () {
    // Get dynamic components
    this.loadings.data = true
    this.permissions = await permissionsApi.getPermissions()
    console.log(this.permissions)
    this.loadings.data = false
  }
}
</script>

<style lang="sass" scoped>
.toolbar-settings
  box-shadow: none !important
  border-radius: 100px 100px 0px 0px
.text-settings
  font-family: Roboto
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 20px
  letter-spacing: 0.4px
</style>
