<template lang="pug">
  v-container
    v-btn.primary(fixed fab bottom right @click="create()")
      v-icon add
    v-row(justify-center)
      v-col(cols='12')
        v-data-table(
          :headers="userProfilesTableHeaders"
          :items="userProfiles"
          item-key="name"
          class="elevation-1"
          :loading="loadings.data"
        )
          template(v-slot:item.color="{ item }")
            v-icon(medium :color='item.color' class="mr-2") mdi-checkbox-blank-circle
          template(v-slot:item.edit="{ item }")
            v-icon(small class="mr-2" @click="editItem(item)") edit
          template(v-slot:item.delete="{ item }")
            v-icon(small @click="deleteItem(item)") delete
    v-dialog(
      v-model='flags.dialog'
      :persistent="loadings.form"
      scrollable=true
      max-width="700px"
    )
      v-form
        v-card(style='border-radius: 13px;')
          v-toolbar.px-4.toolbar-settings.text-settings.white--text(color='primary')
            v-card-title {{flags.create ? 'Novo Perfil de Usuário' : 'Editar Perfil de Usuário'}}
            v-spacer
            v-btn(icon @click='flags.dialog = false')
              v-icon(color='white') mdi-close
          VuePerfectScrollbar
            v-card-text.pa-0
              VuePerfectScrollbar
                v-form
                  v-card(flat tile)
                    v-card-text.pt-0
                      v-container
                        v-row
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Nome
                            v-text-field(v-model='form.name' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Slug
                              v-row.no-gutters
                                v-col.pa-0
                                  v-text-field(v-if='flags.firstEditSlug' v-model='slugAux' :counter='100' :disabled='!flags.editSlug' hide-details dense required outlined)
                                  v-text-field(v-else-if='!flags.editSlug && !flags.firstEditSlug' v-model='slugValue' :counter='100' :disabled='!flags.editSlug' hide-details dense required outlined)
                                  v-text-field(v-else v-model='form.slug' :counter='100' :disabled='!flags.editSlug' hide-details dense required outlined)
                                v-col(cols='auto').pl-2
                                  v-btn(@click="flags.editSlug = true" icon style='display: inline')
                                    v-icon mdi-pencil
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Permissões
                            v-autocomplete(v-model="form.permissions" :items="permissionsList" item-text="name" item-value="id" multiple hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Cor
                            v-autocomplete(outlined v-model="form.color" :items="colorsList" item-text="color" item-value="color" hide-details dense)
                              template(v-slot:item="data")
                                v-divider
                                v-list-item
                                  v-list-item-avatar(size="40" :color="data.item")
                                  v-list-item-content {{data.item}}
                              template(v-slot:selection="data")
                                v-divider
                                v-list-item
                                  v-list-item-avatar(size="20" :color="data.item")
                                  v-list-item-content {{data.item}}
          v-card-actions.pb-3
            v-spacer
            v-scale-transition
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-if="flags.create") Criar Pefil de Usuário
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-else) Salvar Perfil de Usuário
    v-dialog(v-model='deleteInfo.dialog' max-width='600px')
      v-card
      v-form
        v-card(outlined)
          v-card-title Atenção:
          v-card-text Tem certeza que deseja remover esse perfil de usuário?
          v-card-actions
            v-btn.text-none(text @click.stop='deleteInfo.dialog = false') Cancelar
            v-spacer
            v-btn.text-none(color='primary' :loading='deleteInfo.loading' dark @click.stop='callDelete()') Remover
</template>

<script>
import icons from '../assets/icons'
import colors from '../assets/colors'
import { mapState } from 'vuex'
import userProfilesApi from '../api/userprofiles'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import permissionsApi from '../api/permissions'

export default {
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...mapState(['auth']),
    slugValue () {
      return this.string_to_slug(this.form.name)
    }
  },
  data: function () {
    return {
      slugAux: '',
      iconList: icons,
      colorsList: colors,
      swatches: [
        [ '#F44336', '#E91E63', '#9C27B0', '#673AB7' ],
        [ '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4' ],
        [ '#009688', '#4CAF50', '#8BC34A', '#CDDC39' ],
        [ '#FFEB3B', '#FFC107', '#FF9800', '#FF5722' ],
        [ '#795548', '#607D8B', '#9E9E9E', '#000000' ]
      ],
      userProfiles: [],
      userTypeClicked: '',
      userProfilesTableHeaders: [
        {
          text: 'Nome',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Cor',
          align: 'left',
          value: 'color'
        },
        {
          text: 'Editar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'Deletar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'delete'
        }
      ],
      flags: {
        dialog: false,
        create: false,
        editSlug: false,
        firstEditSlug: true
      },
      loadings: {
        data: false,
        form: false
      },
      deleteInfo: {
        dialog: false,
        loading: false
      },
      form: {
        name: '',
        slug: '',
        color: '',
        permissions: []
      },
      permissionsList: []
    }
  },
  watch: {
    'flags.dialog': function (value) {
      if (!value) {
        this.clear()
      }
      this.flags.editSlug = false
      this.flags.firstEditSlug = true
    },
    'form.name': function (newValue, oldValue) {
      if (this.flags.dialog && (oldValue !== '')) {
        this.flags.firstEditSlug = false
      }
    },
    slugValue: {
      deep: true,
      handler: function (newValue) {
        this.form.slug = this.string_to_slug(this.form.name)
      }
    }
  },
  methods: {
    clear () {
      // Clear form
      this.form.name = ''
      this.form.slug = ''
      this.form.color = ''
      this.form.permissions = []
    },
    deleteItem (userType) {
      this.deleteInfo.dialog = true
      this.userTypeClicked = userType
    },
    async callDelete () {
      this.deleteInfo.loading = true
      await userProfilesApi.deleteUserProfile(this.userTypeClicked.id)
      let data = await userProfilesApi.getUserProfiles()
      this.userProfiles = data
      this.userTypeClicked = ''
      this.deleteInfo.loading = false
      this.deleteInfo.dialog = false
    },
    async editItem (userType) {
      console.log(userType)
      // Select userProfile
      this.form.name = userType.name
      this.form.slug = userType.slug
      this.slugAux = userType.slug
      this.form.color = userType.color
      this.form.id = userType.id
      this.form.permissions = userType.permissionId
      // Set edit flags
      this.flags.create = false
      this.flags.dialog = true
    },
    create () {
      // Set create flags
      this.flags.create = true
      this.flags.dialog = true
      this.slugAux = ''
    },
    async save () {
      this.loadings.form = true

      try {
        // Save menu item
        const savedData = {
          name: this.form.name,
          slug: this.form.slug,
          color: this.form.color,
          permissionId: this.form.permissions
        }
        for (let i = 0; savedData.permissionId.length > i; i++) {
          savedData.permissionId[i] = savedData.permissionId[i].toString()
        }
        console.log(savedData)
        // Update data on table
        if (this.flags.create) {
          await userProfilesApi.saveUserProfile(savedData)
          let data = await userProfilesApi.getUserProfiles()
          this.userProfiles = data
        } else {
          await userProfilesApi.updateUserProfile(savedData, this.form.id)
          let data = await userProfilesApi.getUserProfiles()
          this.userProfiles = data
        }
      } catch (err) {
        console.error(err)
      }

      this.loadings.form = false
      this.flags.dialog = false
    },
    string_to_slug (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      var to = 'aaaaeeeeiiiioooouuuunc------'
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    }
  },
  async created () {
    // Get dynamic components
    this.loadings.data = true
    let data = await userProfilesApi.getUserProfiles()
    this.permissionsList = await permissionsApi.getPermissions()
    this.userProfiles = data
    console.log(this.userProfiles)
    this.loadings.data = false
  }
}
</script>

<style lang="sass" scoped>
.toolbar-settings
  box-shadow: none !important
  border-radius: 100px 100px 0px 0px
.text-settings
  font-family: Roboto
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 20px
  letter-spacing: 0.4px
</style>
