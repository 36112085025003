<template lang="pug">
  v-container.align-start.pa-0(fill-height fluid)
    v-dialog(v-model='addDialog' max-width="700")
      template(v-slot:activator='{ on }')
        v-btn.primary(v-on='on' fixed fab bottom right :class="fabClass")
          v-icon add
      perfect-scrollbar
        AddContactDialogBody(v-if="tab === 0" :contacts="jsonContacts" v-on:close-dialog="closeDialog")
        AddCategoryDialogBody(v-else-if="tab === 1" :categories="categories" v-on:close-dialog="closeDialog")
    v-tabs.mt-5.primary(v-model='tab' centered fixed-tabs)
      v-tab() Contatos
      v-tab-item()
        v-container
          v-row.justify-center(v-if="loading")
            v-progress-circular(size="70" color="primary" indeterminate)
          v-list(subheader v-else)
            v-subheader() Contatos
            v-row.align-start(:justify="justifyBySize")
              v-col(cols='auto' v-for="(contact, i) in contacts" :key="i")
                v-list-item
                  v-card(width="400" @click="contactShow(i)")
                    v-list-item(three-line)
                      v-list-item-content.align-self.start.align-start()
                        v-list-item-title {{contact.title}}
                        v-list-item-subtitle {{contact.subtitle}}
                      v-list-item-avatar(tile size=125)
                        div(v-html='contact.icon' v-if="!contact.display")
                        v-icon(:color="contact.color" v-else) {{contact.icon}}
            v-subheader() Redes Sociais
            v-row.align-start(:justify="justifyBySize")
              v-col(cols='auto' v-for="(social, i) in socials" :key="social.title")
                v-list-item
                  v-card(width="400" @click="socialMediaShow(i)")
                    v-list-item(three-line)
                      v-list-item-content.align-self.start.align-start()
                        v-list-item-title {{social.title}}
                        v-list-item-subtitle {{social.subtitle}}
                      v-list-item-avatar(tile size=125)
                        div(v-html='social.icon' v-if="!social.display")
                        v-icon(:color="social.color" v-else) {{social.icon}}
      v-tab() Categorias
      v-tab-item()
        v-container
          div(v-if="!categories")
            v-row.justify-center
              v-progress-circular(size="70" indeterminate color="primary")
          div(v-else)
            v-list()
              div(v-for="(category,index) in categories" :key="category.id")
                //- v-divider(v-if='index > 0')
                v-list-item(@click="selectCategory(category, index)" class="ml-0 mr-0 pl-0 pr-0")
                  v-alert.pa-0.ma-0.mb-2( border="left" colored-border :color="category.color" elevation="0" width="100%" height="50")
                    v-row.ml-5
                      v-list-item-avatar
                        v-icon(:color="category.color") {{category.icon}}
                      v-list-item-content {{category.name}}
    v-dialog(v-model="showContact" max-width="700" scrollable=true)
      AddContactDialogBody(:contacts="jsonContacts" :index="index" :showingSocialMedia="showingSocialMedia" v-on:close-dialog="closeDialog")
    v-dialog(v-model="showCategory" max-width="700")
      AddCategoryDialogBody(v-if="showCategory" :categories="categories" :index="categoryIndex" :selectedCategory="selectedCategory" v-on:close-dialog="closeDialog")
    v-snackbar( v-model="snackbar.enable" :timeout="6000" top right ) {{ snackbar.text }}
        v-btn( color="pink" text @click="snackbar.enable = false" ) Fechar
</template>
<script>
import contacts from '../api/organization.js'
import AddContactDialogBody from '../components/AddContactDialogBody'
import AddCategoryDialogBody from '../components/AddCategoryDialogBody'
import post from '../api/posts'
export default {
  components: {
    AddContactDialogBody,
    AddCategoryDialogBody
  },
  data () {
    return {
      contacts: null,
      socials: null,
      showCategory: false,
      loading: true,
      tab: null,
      addDialog: false,
      selectedCategory: null,
      jsonContacts: null,
      fabClass: '',
      showingSocialMedia: false,
      index: null,
      categoryIndex: null,
      showContact: false,
      loadingData: true,
      snackbar: {
        enable: false,
        text: ''
      },
      categories: null
    }
  },
  methods: {
    selectCategory (category, index) {
      this.selectedCategory = category
      this.categoryIndex = index
      this.showCategory = true
    },
    goHome () {
      this.$router.push({
        name: 'home'
      })
    },
    contactShow (index) {
      this.showContact = true
      this.index = index
      this.showingSocialMedia = false
    },
    socialMediaShow (index) {
      this.showContact = true
      this.index = index
      this.showingSocialMedia = true
    },
    closeDialog () {
      this.showContact = false
      this.addDialog = false
      this.showCategory = false
      this.snackbar.text = 'Operação concluida com sucesso'
      this.snackbar.enable = true
    },
    setFABClass (data) {
      if (!data) {
        this.fabClass = 'mb-12'
      } else {
        this.fabClass = ''
      }
    }
  },
  computed: {
    justifyBySize () {
      return this.$vuetify.breakpoint.mdAndDown ? 'center' : 'start'
    },
    showingDrawer () {
      return this.$store.getters['app/showingDrawer']
    }
  },
  watch: {
    showingDrawer (data) {
      this.setFABClass(data)
    }
  },
  async created () {
    this.setFABClass(this.showingDrawer)
    var res = await contacts.list(this.$route.params.institution)
    this.loading = false
    this.contacts = res.data.contacts
    this.socials = res.data.socialnetworks
    this.jsonContacts = res.data

    this.categories = (await post.getCategories()).data
  }
}
</script>
<style scoped lang="stylus">

</style>
