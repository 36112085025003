<template lang="pug">
 v-app#404
  v-container(fluid='' fill-height='')
    v-layout(align='center' justify='center')
      .text-md-center
        h1 404
        h2.my-3.headline Sorry, page not found
        div
          v-btn(color='primary' @click='goHome') Go Home
</template>

<script>
export default {
  methods: {
    goHome () {
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>
<style scoped lang="stylus">
  h1
    font-size: 150px
    line-height: 150px
    font-weight: 700
    color: #252932
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px
</style>
