<template lang="pug">
  v-container
    v-dialog(
      v-model='flags.edit'
      max-width="600px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      UserManager(
        :visible="flags.edit"
        :user="selectedUser"
        @close="flags.edit = false"
        @saved="saveUser"
      )
    v-row(v-if="!loadings.initial")
      v-col(
        v-for="(item, index) in list"
        :key="item.id"
        xs=12
        sm=6
        md=6
        lg=4
        xl=3
      )
        v-card(
          flat
          max-width=400
        )
          v-list-item(@click="openUserDialog(item)")
            v-list-item-avatar(
              v-if="item.profile_image_url"
              size="80"
            )
              img(:src="item.profile_image_url")
            v-list-item-avatar(
              v-else
              size="80"
            )
              v-icon(
                size="80"
                color="secondary"
              ) mdi-account-circle
            v-list-item-content
                v-list-item-title {{ item.name }}
                v-list-item-subtitle {{ getTypeName(item.type) }}
    v-row.mt-5.justify-center(v-if="flags.noListItem")
      span.subtitle-1 Nenhum usuário encontrado
      v-icon.ml-2(color="secondary") mdi-emoticon-sad-outline
    v-row.justify-center(v-if="loadings.loadMore || loadings.searchUsers || loadings.initial")
      v-progress-circular.mt-5(
        :size="50"
        color="primary"
        indeterminate
      )
    v-navigation-drawer.text-center(
      v-model="flags.filter"
      fixed
      temporary
      clipped
      right
    )
      v-card(flat)
        v-card-title Filtros de usuários
        v-card-text(v-if="!loadings.initial")
          v-row.mr-1.ml-1
            v-select(
              v-model="filterTypes"
              :items="users.types"
              item-text="name"
              item-value="slug"
              label="Tipo do usuário"
              filled
              rounded
              clearable
              multiple
            )
              template(v-slot:selection="{ item, index }")
                span(
                  v-if="index === 0"
                  class="grey--text caption"
                ) {{ filterTypes.length }} {{ filterTypes.length === 1 ? 'seleção' : 'seleções' }}
          v-row.mr-1.ml-1
            v-select(
              v-model="filterProfiles"
              :items="users.profiles"
              item-text="name"
              item-value="name"
              label="Perfil do usuário"
              filled
              rounded
              clearable
              multiple
            )
              template(v-slot:selection="{ item, index }")
                span(
                  v-if="index === 0"
                  class="grey--text caption"
                ) {{ filterProfiles.length }} {{ filterProfiles.length === 1 ? 'seleção' : 'seleções' }}
        v-row.justify-center(v-else)
          v-progress-circular.mt-5.mb-5(
            :size="50"
            color="primary"
            indeterminate
          )
        v-card-actions
          v-row
          v-spacer
          v-btn(
            depressed
            color="primary"
            @click="applyFilter"
          )
            | Filtrar
    v-btn(
      @click="openUserDialog(null)"
      fixed
      fab
      bottom
      right
      color="primary"
      :class="!showingDrawer ? 'mb-12' : undefined"
      :loading="loadings.initial"
    )
      v-icon add
</template>
<script>
import userApi from '../api/user'
import UserManager from '../components/users/UserManager'
import { createModuleLogger } from '../configs/winston'
import { mapState } from 'vuex'

const l = createModuleLogger('Users')

export default {
  components: {
    UserManager
  },
  data () {
    return {
      lastQuery: 0,
      flags: {
        edit: false,
        add: false,
        filter: false,
        noListItem: false,
        lastPage: false
      },
      loadings: {
        searchUsers: true,
        loadMore: false,
        initial: false
      },
      filterTypes: [],
      filterProfiles: [],
      filter: {
        types: [],
        profiles: []
      },
      list: [],
      selectedUser: null,
      next: 0,
      offset: 30
    }
  },
  computed: {
    ...mapState(['search', 'users']),
    showingDrawer () {
      return this.$store.getters['app/showingDrawer']
    }
  },
  watch: {
    'search.text': function (text) {
      l.info('Search text changed')

      this.searchUsers(text)
    },
    'search.filter': function (value) {
      if (value) {
        this.flags.filter = value
      }
    },
    'flags.filter': function (value) {
      if (!value) {
        this.$store.commit('search/toggleFilter')
      }
    },
    '$route.query': function () {
      if (this.$route.query && this.$route.query.types) {
        this.filterTypes = this.$route.query.types
      }
      this.$nextTick(() => {
        console.log('Searching users')
        this.searchUsers()
      })
    }
  },
  methods: {
    isEmpty (obj) {
      return Object.keys(obj).length === 0
    },
    applyFilter () {
      this.searchUsers()
      this.flags.filter = false
    },
    openUserDialog (user) {
      this.selectedUser = user
      this.flags.edit = true
    },
    saveUser (data) {
      const user = this.list.find(u => u.id === data.id)

      if (user) {
        Object.assign(user, data)
        this.flags.edit = false
      } else {
        this.searchUsers()
        this.flags.add = false
      }
    },
    getTypeName (slug) {
      const type = this.users.types.find(t => t.slug === slug)
      return type && type.name ? type.name : 'O usuário não possui um tipo'
    },
    getNameInitials (user) {
      const splitName = user.name.split(' ')
      const firstName = splitName[0]
      const lastName = splitName[splitName.length - 1]

      return firstName[0] + lastName[0]
    },
    resetList () {
      this.list = []
      this.next = 0
    },
    async handleScroll () {
      let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

      if (bottomOfWindow && !(this.loadings.loadMore || this.loadings.searchUsers)) {
        await this.loadMore()
      }
    },
    async searchUsers () {
      // this.$nextTick(() => {
      //   console.log('changed after execution of this')
      //   this.lastQueryOptions = lastQueryOptions
      // })
      const lastQuery = ++this.lastQuery

      l.info('Searching users')

      this.resetList()
      this.flags.noListItem = false
      this.flags.lastPage = false
      this.loadings.searchUsers = true

      // const usersTypesSlugs = this.filterTypes.map(t => t.slug)
      // const usersProfilesSlugs = this.filterProfiles.map(p => p.name)
      const usersTypesSlugs = this.filterTypes
      const usersProfilesSlugs = this.filterProfiles

      const list = await userApi.list({
        types: usersTypesSlugs && usersTypesSlugs.length > 0 ? usersTypesSlugs : undefined,
        profiles: usersProfilesSlugs && usersProfilesSlugs.length > 0 ? usersProfilesSlugs : undefined,
        next: this.next,
        offset: this.offset,
        search: this.search.text || null
      })

      if (lastQuery === this.lastQuery) {
        if (list.length > 0) {
          // If there're search results and search text hasn't changed while waiting for api

          l.info(`${list.length} user(s) found`)

          this.list.push(...list)
          this.loadings.searchUsers = false
        } else if (list.length === 0) {
          // If there're no search results and search text hasn't changed while waiting for api

          l.info('No search result')

          this.flags.noListItem = true
          this.loadings.searchUsers = false
        }
      } else {
        l.info(' Query index isnt the last ', lastQuery)
      }
    },
    async loadMore () {
      if (!this.loadings.searchUsers && !this.loadings.loadMore && !this.flags.lastPage) {
        l.info('Loading more users')

        this.loadings.loadMore = true

        this.next = this.list[this.list.length - 1].id + 1

        const usersTypesSlugs = this.filterTypes.map(t => t.slug)
        const usersProfilesSlugs = this.filterProfiles.map(p => p.name)

        const list = await userApi.list({
          types: usersTypesSlugs.length > 0 ? usersTypesSlugs : undefined,
          profiles: usersProfilesSlugs.length > 0 ? usersProfilesSlugs : undefined,
          next: this.next,
          offset: this.offset,
          search: this.search.text || null
        })

        this.loadings.loadMore = false

        if (list.length > 0 && this.next > 0) {
          l.info(`${list.length} user(s) got`)

          this.list.push(...list)
        } else if (list.length === 0 && this.next > 0) {
          l.info('No more users')
          this.flags.lastPage = true
        }
      }
    }
  },
  async created () {
    this.searchUsers()

    this.loadings.initial = true
    await this.$store.dispatch('users/listTypes')
    await this.$store.dispatch('users/listProfiles')
    await this.$store.dispatch('groups/list')
    this.loadings.initial = false
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  activated () {
    console.log('Calling activated')
  },
  deactivated () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  url: {
    filterTypes: 'types',
    filterProfiles: 'profiles'
  }
}
</script>
