<template lang="pug">
  v-container
    v-btn.primary(fixed fab bottom right @click="create()")
      v-icon add
    v-row(justify-center)
      v-col(cols='12')
        v-data-table(
          :headers="menuItensTableHeaders"
          :items="menuItens"
          item-key="name"
          class="elevation-1"
          :loading="loadings.data"
        )
          template(v-slot:item.edit="{ item }")
            v-icon(small class="mr-2" @click="editItem(item)") edit
          template(v-slot:item.delete="{ item }")
            v-icon(small @click="deleteItem(item)") delete
    v-dialog(
      v-model='flags.dialog'
      :persistent="loadings.form"
      scrollable=true
      max-width="700px"
    )
      v-form
        v-card(style='border-radius: 13px;')
          v-toolbar.px-4.toolbar-settings.text-settings.white--text(color='primary')
            v-card-title {{flags.create ? 'Novo Item de Menu' : 'Editar Item de Menu'}}
            v-spacer
            v-btn(icon @click='flags.dialog = false')
              v-icon(color='white') mdi-close
          VuePerfectScrollbar
            v-card-text.pa-0
              VuePerfectScrollbar
                v-form
                  v-card(flat tile)
                    v-card-text.pt-0
                      v-container
                        v-row
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Título do Item de Menu
                            v-text-field(v-model='form.title' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Nome do Item de Menu
                            v-text-field(v-model='form.name' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Permissão
                            v-autocomplete(v-model="form.permission" :items="permissionsList" item-text="name" return-object hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Seção
                            v-combobox(v-model='form.section' :items='items' :search-input.sync='search' hide-selected label='Selecione ou crie uma seção' deletable-chips persistent-hint small-chips hide-details outlined )
                              template(v-slot:no-data)
                                v-list-item
                                  v-list-item-content
                                    v-list-item-title
                                      | Sem resultados "
                                      strong {{ search }}
                                      | ". Pressione&nbsp;
                                      kbd enter
                                      |  para adicionar uma
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Ícones
                            v-autocomplete(v-model="form.icon" :items="iconList" hide-details dense required outlined)
                              template(v-slot:item="data")
                                  v-divider
                                  v-list-item
                                    v-list-item-avatar(size="40")
                                      v-icon {{data.item}}
                                    v-list-item-content {{data.item}}
                              template(v-slot:selection="data")
                                  v-divider
                                  v-list-item
                                    v-list-item-avatar(size="20")
                                      v-icon {{data.item}}
                                    v-list-item-content {{data.item}}
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium Caminho
                            v-text-field(v-model='form.path' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium URL Externa
                            v-text-field(v-model='form.external_url' :counter='100' hide-details dense required outlined)
                          v-col.pb-1(cols='12')
                            span.subtitle-1.font-weight-medium URL Alvo Externa
                            v-text-field(v-model='form.external_url_target' :counter='100' hide-details dense required outlined)
          v-card-actions.pb-3
            v-spacer
            v-scale-transition
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-if="flags.create") Criar Item de Menu
              v-btn(@click="save()" outlined color='primary' :loading="loadings.form" v-else) Salvar Item de Menu
    v-dialog(v-model='deleteInfo.dialog' max-width='600px')
      v-card
      v-form
        v-card(outlined)
          v-card-title Atenção:
          v-card-text Tem certeza que deseja remover esse item?
          v-card-actions
            v-btn.text-none(text @click.stop='deleteInfo.dialog = false') Cancelar
            v-spacer
            v-btn.text-none(color='primary' :loading='deleteInfo.loading' dark @click.stop='callDelete()') Remover
</template>

<script>
import icons from '../assets/icons'
import colors from '../assets/colors'
import { mapState } from 'vuex'
import menuItensApi from '../api/menuitens'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import permissionsApi from '../api/permissions'

export default {
  components: {
    VuePerfectScrollbar
  },
  computed: {
    ...mapState(['auth'])
  },
  data: function () {
    return {
      search: null,
      iconList: icons,
      colorsList: colors,
      menuItens: [],
      menuItemClicked: '',
      items: [ 'Administração', 'Configurações', 'string', 'example-section' ],
      menuItensTableHeaders: [
        {
          text: 'Título',
          align: 'left',
          value: 'title'
        },
        {
          text: 'Seção',
          align: 'left',
          value: 'section'
        },
        {
          text: 'Editar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        {
          text: 'Deletar',
          width: '100',
          align: 'center',
          sortable: false,
          value: 'delete'
        }
      ],
      flags: {
        dialog: false,
        create: false
      },
      loadings: {
        data: false,
        form: false
      },
      deleteInfo: {
        dialog: false,
        loading: false
      },
      form: {
        title: '',
        name: '',
        section: '',
        group: '',
        icon: '',
        path: '',
        external_url: '',
        external_url_target: '',
        id: '',
        permission: ''
      },
      permissionsList: []
    }
  },
  watch: {
    'flags.dialog': function (value) {
      if (!value) {
        this.clear()
      }
    }
  },
  methods: {
    clear () {
      // Clear form
      this.form.title = ''
      this.form.name = ''
      this.form.section = ''
      this.form.group = ''
      this.form.icon = ''
      this.form.path = ''
      this.form.external_url = ''
      this.form.external_url_target = ''
      this.form.id = ''
      this.form.permission = ''
    },
    deleteItem (menuItem) {
      this.deleteInfo.dialog = true
      this.menuItemClicked = menuItem
    },
    async callDelete () {
      this.deleteInfo.loading = true
      await menuItensApi.deleteMenuItem(this.menuItemClicked.id)
      let data = await menuItensApi.getMenuItems()
      this.menuItens = data
      this.menuItemClicked = ''
      this.deleteInfo.loading = false
      this.deleteInfo.dialog = false
    },
    async editItem (menuItem) {
      console.log(menuItem)
      // Select menuItem
      this.form.title = menuItem.title
      this.form.name = menuItem.name
      this.form.section = menuItem.section
      this.form.group = menuItem.group
      this.form.icon = menuItem.icon
      this.form.path = menuItem.path
      this.form.external_url = menuItem.external_url
      this.form.external_url_target = menuItem.external_url_target
      this.form.id = menuItem.id
      this.form.permission = menuItem.Permission
      // Set edit flags
      this.flags.create = false
      this.flags.dialog = true
    },
    create () {
      // Set create flags
      this.flags.create = true
      this.flags.dialog = true
    },
    async save () {
      this.loadings.form = true

      try {
        // Save menu item
        if (this.form.external_url === '') {
          this.form.external_url = null
        }
        if (this.form.external_url_target === '') {
          this.form.external_url_target = null
        }
        const savedData = {
          title: this.form.title,
          name: this.form.name,
          section: this.form.section,
          group: this.form.group,
          icon: this.form.icon,
          path: this.form.path,
          external_url: this.form.external_url,
          external_url_target: this.form.external_url_target,
          permission: this.form.permission
        }
        console.log(savedData)
        // Update data on table
        if (this.flags.create) {
          await menuItensApi.saveMenuItem(savedData)
          let data = await menuItensApi.getMenuItems()
          this.menuItens = data
        } else {
          await menuItensApi.updateMenuItem(savedData, this.form.id)
          let data = await menuItensApi.getMenuItems()
          this.menuItens = data
        }
      } catch (err) {
        console.error(err)
      }

      this.loadings.form = false
      this.flags.dialog = false
    }
  },
  async created () {
    // Get dynamic components
    this.loadings.data = true
    let data = await menuItensApi.getMenuItems()
    this.permissionsList = await permissionsApi.getPermissions()
    this.menuItens = data
    console.log(this.menuItens)
    this.loadings.data = false
  }
}
</script>

<style lang="sass" scoped>
.toolbar-settings
  box-shadow: none !important
  border-radius: 100px 100px 0px 0px
.text-settings
  font-family: Roboto
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 20px
  letter-spacing: 0.4px
</style>
